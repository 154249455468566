import React, { useState } from 'react';
import KostenrechnerForm from './Form';
import Ergebnisse from './Summary';
import './css/index.css';
import './css/card.css';
import './css/form.css';
import './css/button.css';
import './css/result.css';
import './css/theme.css';

function App() {
  const [ergebnisse, setErgebnisse] = useState({
    ersparnisJahr: undefined,
    ersparnisVierJahre: undefined,
    co2Ersparnis: undefined,
  });

  const handleCalculate = (data) => {
    const {
      kraftstoffpreis,
      verbrauchVerbrenner,
      preisProKWh,
      stromverbrauch,
      laufleistung,
      kraftstoffart,
      stromart,
    } = data;

    // Beispielwerte für CO2-Emissionen
    const co2ProLiter = {
      Diesel: 1.68,
      'Super E5': 1.87,
      'Super E10': 1.81,
      'Super Plus': 1.98,
    };

    const co2ProKWh = stromart === 'Ökostrom' ? 0.05 : 0.4;

    // Berechnungen
    const kostenVerbrenner = (kraftstoffpreis * verbrauchVerbrenner * laufleistung) / 100;
    const kostenElektro = (preisProKWh * stromverbrauch * laufleistung) / 100;
    const ersparnisJahr = kostenVerbrenner - kostenElektro;
    const ersparnisVierJahre = ersparnisJahr * 4;

    const co2EmissionVerbrenner =
      (co2ProLiter[kraftstoffart] * verbrauchVerbrenner * laufleistung) / 100;
    const co2EmissionElektro = (co2ProKWh * stromverbrauch * laufleistung) / 100;
    const co2Ersparnis = co2EmissionVerbrenner - co2EmissionElektro;

    setErgebnisse({
      ersparnisJahr,
      ersparnisVierJahre,
      co2Ersparnis,
    });
  };

  return (
    <div>
      <KostenrechnerForm onCalculate={handleCalculate} />
      <Ergebnisse {...ergebnisse} />
    </div>
  );
}

export default App;
