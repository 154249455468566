import React from 'react';
import './css/metric.css';

function Ergebnisse({ ersparnisJahr, ersparnisVierJahre, co2Ersparnis }) {
  // Prüfen, ob Ergebnisse vorhanden sind
  if (ersparnisJahr === undefined) {
    return <div></div>;
  }

  // Funktion zur Formatierung der Zahlen mit einem Komma als Dezimaltrennzeichen
  const formatNumberWithComma = (value) => {
    return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="metric">
      {/* Bedingung hinzufügen, um den Text nur anzuzeigen, wenn ersparnisJahr >= 0 */}
      {ersparnisJahr >= 0 && (
        <div className="metric-title">
          Für Ihre Eingabe wäre ein E-Auto um <span>{formatNumberWithComma(ersparnisJahr)} €</span>
          pro Jahr günstiger
        </div>
      )}
      {ersparnisJahr <= 0 && (
        <div className="metric-title">Für Ihre Eingabe hätte ein E-Auto keinen Preisvorteil.</div>
      )}
      <div className="metric-inner">
        <div className="metric-item">
          <div className="metric-title">Ersparnis pro Jahr:</div>
          <div className="metric-number">{formatNumberWithComma(ersparnisJahr)} €</div>
        </div>
        <div className="metric-item">
          <div className="metric-title">Ersparnis in 4 Jahren:</div>
          <div className="metric-number">{formatNumberWithComma(ersparnisVierJahre)} €</div>
        </div>
        <div className="metric-item">
          <div className="metric-title">CO2-Emission Ersparnis pro Jahr:</div>
          <div className="metric-number">{formatNumberWithComma(co2Ersparnis)} kg</div>
        </div>
      </div>
    </div>
  );
}

export default Ergebnisse;
