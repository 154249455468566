import React, { useState, useEffect } from 'react';

function KostenrechnerForm({ onCalculate }) {
  const [kraftstoffart, setKraftstoffart] = useState('Diesel'); // Default-Wert
  const [kraftstoffpreis, setKraftstoffpreis] = useState(null);
  const [verbrauchVerbrenner, setVerbrauchVerbrenner] = useState('8,50');
  const [stromart, setStromart] = useState('Ökostrom');
  const [preisProKWh, setPreisProKWh] = useState('0,40 €');
  const [stromverbrauch, setStromverbrauch] = useState('17,50');
  const [laufleistung, setLaufleistung] = useState('10000');
  const [ergebnisVerbrenner, setErgebnisVerbrenner] = useState(null);
  const [ergebnisElektro, setErgebnisElektro] = useState(null);

  const getApiKraftstoffart = (kraftstoffart) => {
    const mapping = {
      Diesel: 'diesel',
      'Super E5': 'e5',
      'Super E10': 'e10',
    };
    return mapping[kraftstoffart] || 'diesel';
  };

  useEffect(() => {
    fetchKraftstoffpreise();
  }, []);

  const fetchKraftstoffpreise = async () => {
    const lat = 52.52;
    const lng = 13.405;
    const rad = 5;
    const apiKey = '51567e6f-d7c8-c0bb-77a2-23ced932a227';
    const apiKraftstoffart = getApiKraftstoffart(kraftstoffart);

    const url = `https://creativecommons.tankerkoenig.de/json/list.php?lat=${lat}&lng=${lng}&rad=${rad}&sort=dist&type=${apiKraftstoffart}&apikey=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.ok && data.stations.length > 0) {
        const preise = data.stations.slice(0, 5).map((station) => station.price);
        const durchschnittspreis = preise.reduce((acc, curr) => acc + curr, 0) / preise.length;
        setKraftstoffpreis(durchschnittspreis.toFixed(2).replace('.', ',') + ' €');
      } else {
        console.log('Keine Tankstellen gefunden oder API-Fehler:', data.message);
        setKraftstoffpreis('1,78 €');
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Kraftstoffpreise:', error);
      setKraftstoffpreis('1,78 €');
    }
  };

  const handleKraftstoffpreisChange = (e) => {
    const value = e.target.value.replace(/[^\d,€]/g, ''); // Erlaubt nur Zahlen, Komma und €-Zeichen
    setKraftstoffpreis(value);
  };

  // Anpassung der Kraftstoffart-Auswahl, um die Preise zu aktualisieren
  const handleKraftstoffartChange = (e) => {
    const neueKraftstoffart = e.target.value;
    setKraftstoffart(neueKraftstoffart);
    fetchKraftstoffpreise();
  };

  // Handler für die Eingabe von preisProKWh, inklusive Währungssymbol
  const handlePreisProKWhChange = (e) => {
    const value = e.target.value.replace(/[^\d,€]/g, ''); // Erlaubt nur Zahlen, Komma und €-Zeichen
    setPreisProKWh(value);
  };

  // Funktion zur Konvertierung des formatierten Strings in einen float für Berechnungen
  const convertToNumber = (value) => {
    const numberValue = value.replace('€', '').replace(',', '.');
    return parseFloat(numberValue);
  };

  const berechneKosten = () => {
    // Konvertiere Eingabewerte zu numerischen Werten für die Berechnung
    const kraftstoffpreisNum = parseFloat(kraftstoffpreis.replace(',', '.'));
    const preisProKWhNum = parseFloat(preisProKWh.replace(',', '.'));
    const laufleistungNum = parseInt(laufleistung, 10);
    const verbrauchVerbrennerNum = parseFloat(verbrauchVerbrenner.replace(',', '.'));
    const stromverbrauchNum = parseFloat(stromverbrauch.replace(',', '.'));

    // Berechne die jährlichen Kosten für Verbrenner und Elektrofahrzeuge
    const kostenVerbrenner =
      ((kraftstoffpreisNum * verbrauchVerbrennerNum) / 100) * laufleistungNum;
    const kostenElektro = ((preisProKWhNum * stromverbrauchNum) / 100) * laufleistungNum;

    setErgebnisVerbrenner(kostenVerbrenner.toFixed(2).replace('.', ',') + ' €');
    setErgebnisElektro(kostenElektro.toFixed(2).replace('.', ',') + ' €');
  };

  // Formular-Submit-Handler
  const handleSubmit = (event) => {
    event.preventDefault();
    const kraftstoffpreisNum = convertToNumber(kraftstoffpreis);
    const preisProKWhNum = convertToNumber(preisProKWh);

    // Berechnungen durchführen und Ergebnisse an die Elternkomponente übergeben
    onCalculate({
      kraftstoffpreis: kraftstoffpreisNum,
      preisProKWh: preisProKWhNum,
      kraftstoffart,
      verbrauchVerbrenner: parseFloat(verbrauchVerbrenner.replace(',', '.')),
      stromart,
      stromverbrauch: parseFloat(stromverbrauch.replace(',', '.')),
      laufleistung: parseInt(laufleistung, 10),
    });

    event.preventDefault();
    berechneKosten();
  };

  const berechneEmissionErsparnis = () => {
    const preisNum = parseFloat(kraftstoffpreis.replace(',', '.').replace(' €', ''));
    // Nehmen Sie an, dass dies die Methode zur Berechnung der Ersparnis ist
    const ersparnis = preisNum * 0.85; // Beispielberechnung
    console.log(`CO2-Emission Ersparnis pro Jahr: ${ersparnis}`);
  };

  useEffect(() => {
    // Dieser useEffect wird ausgeführt, sobald kraftstoffpreis gesetzt oder aktualisiert wird.
    if (kraftstoffpreis) {
      // Berechne nur, wenn ein gültiger Kraftstoffpreis vorhanden ist.
      berechneEmissionErsparnis();
    }
  }, [kraftstoffpreis]);

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div class="card">
        <div class="card-inner">
          <div class="card-grid card--grid-2">
            <div class="card-item">
              <div class="card-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13H8M2 9L4 10L5.27064 6.18807C5.53292 5.40125 5.66405 5.00784 5.90729 4.71698C6.12208 4.46013 6.39792 4.26132 6.70951 4.13878C7.06236 4 7.47705 4 8.30643 4H15.6936C16.523 4 16.9376 4 17.2905 4.13878C17.6021 4.26132 17.8779 4.46013 18.0927 4.71698C18.3359 5.00784 18.4671 5.40125 18.7294 6.18807L20 10L22 9M16 13H19M6.8 10H17.2C18.8802 10 19.7202 10 20.362 10.327C20.9265 10.6146 21.3854 11.0735 21.673 11.638C22 12.2798 22 13.1198 22 14.8V17.5C22 17.9647 22 18.197 21.9616 18.3902C21.8038 19.1836 21.1836 19.8038 20.3902 19.9616C20.197 20 19.9647 20 19.5 20H19C17.8954 20 17 19.1046 17 18C17 17.7239 16.7761 17.5 16.5 17.5H7.5C7.22386 17.5 7 17.7239 7 18C7 19.1046 6.10457 20 5 20H4.5C4.03534 20 3.80302 20 3.60982 19.9616C2.81644 19.8038 2.19624 19.1836 2.03843 18.3902C2 18.197 2 17.9647 2 17.5V14.8C2 13.1198 2 12.2798 2.32698 11.638C2.6146 11.0735 3.07354 10.6146 3.63803 10.327C4.27976 10 5.11984 10 6.8 10Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="card-text">
                <div class="card-headline">
                  <h1>Verbrenner</h1>
                </div>
                <div className="form-element">
                  <div className="form-element-label">
                    <label> Kraftstoffart:</label>
                  </div>
                  <div className="select-native">
                    <div className="select-icon"></div>
                    <select
                      className="select"
                      value={kraftstoffart}
                      onChange={handleKraftstoffartChange}
                    >
                      <option value="Diesel">Diesel</option>
                      <option value="Super E5">Super E5</option>
                      <option value="Super E10">Super E10</option>
                      <option value="Super Plus">Super Plus</option>
                    </select>
                  </div>
                </div>
                <div className="form-element">
                  <div className="form-element-label">
                    <label>Kraftstoffpreis pro Liter:</label>
                  </div>
                  <input
                    className="input form-element-field"
                    type="text"
                    value={kraftstoffpreis}
                    onChange={handleKraftstoffpreisChange}
                    step="0.1"
                  />
                </div>

                <div className="form-element">
                  <div className="form-element-label">
                    <label> Verbrauch (Verbrenner) in Liter pro 100 km:</label>
                  </div>
                  <input
                    className="input form-element-field"
                    type="text"
                    value={verbrauchVerbrenner}
                    onChange={(e) => setVerbrauchVerbrenner(e.target.value)}
                    step="0.1"
                  />
                </div>
                <div class="form-submit-container">
                  <div className="form-element">
                    <div className="form-element-label">
                      <label>Jährliche Laufleistung:</label>
                    </div>
                    <div className="select-native">
                      <div className="select-icon"></div>
                      <select
                        className="select"
                        value={laufleistung}
                        onChange={(e) => setLaufleistung(e.target.value)}
                      >
                        <option value="10000">10000 km</option>
                        <option value="15000">15000 km</option>
                        <option value="20000">20000 km</option>
                        <option value="25000">25000 km</option>
                        <option value="30000">30000 km</option>
                        <option value="35000">35000 km</option>
                        <option value="40000">40000 km</option>
                        <option value="45000">45000 km</option>
                        <option value="50000">50000 km</option>
                        <option value="55000">55000 km</option>
                        <option value="60000">60000 km</option>
                        <option value="65000">65000 km</option>
                        <option value="70000">70000 km</option>
                        <option value="75000">75000 km</option>
                        <option value="80000">80000 km</option>
                        <option value="85000">85000 km</option>
                        <option value="90000">90000 km</option>
                        <option value="95000">95000 km</option>
                        <option value="100000">100000 km</option>
                      </select>
                    </div>
                  </div>
                  <button className="button button--primary button--medium" type="submit">
                    Berechnen
                  </button>
                </div>
              </div>
            </div>

            <div class="card-item">
              <div class="card-icon">
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 1L2.09344 11.6879C1.74463 12.1064 1.57023 12.3157 1.56756 12.4925C1.56524 12.6461 1.63372 12.7923 1.75324 12.8889C1.89073 13 2.16316 13 2.70802 13H9.99998L8.99998 21L17.9065 10.3121C18.2553 9.89358 18.4297 9.68429 18.4324 9.50754C18.4347 9.35388 18.3663 9.2077 18.2467 9.11111C18.1092 9 17.8368 9 17.292 9H9.99998L11 1Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="card-text">
                <div class="card-headline">
                  <h1>Elektro</h1>
                </div>
                <div className="form-element">
                  <div className="form-element-label">
                    <label>Stromart:</label>
                  </div>
                  <div className="select-native">
                    <div className="select-icon"></div>
                    <select
                      className="select"
                      value={stromart}
                      onChange={(e) => setStromart(e.target.value)}
                    >
                      <option value="Ökostrom">Ökostrom</option>
                      <option value="Strommix">Strommix</option>
                    </select>
                  </div>
                </div>
                <div className="form-element">
                  <div className="form-element-label">
                    <label>Preis pro kWh:</label>
                  </div>
                  <input
                    type="text"
                    className="input form-element-field"
                    value={preisProKWh}
                    onChange={handlePreisProKWhChange}
                    step="0.01"
                  />
                </div>

                <div className="form-element">
                  <div className="form-element-label">
                    <label>Stromverbrauch pro 100 km in kWh:</label>
                  </div>
                  <input
                    type="text"
                    className="input form-element-field"
                    value={stromverbrauch}
                    onChange={(e) => setStromverbrauch(e.target.value)}
                    step="0.01"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="results">
            <div className="result">
              {ergebnisVerbrenner && (
                <div>
                  <h2>Kosten pro Jahr: {ergebnisVerbrenner}</h2>
                </div>
              )}
            </div>
            <div className="result">
              {ergebnisElektro && (
                <div>
                  <h2>Kosten pro Jahr: {ergebnisElektro}</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default KostenrechnerForm;
